import * as classNames from 'classnames'
import BaseCard from 'components/BaseCard'
import Button from 'components/Button'
import { If } from '@redsales/ds'
import { navigate } from 'gatsby'
import { useLoans } from 'hooks/useLoans'

import './LoanCard.scss'
import makeTrackings from 'utils/make/trackings'
import { loansFormId } from 'utils/make/constants'

const LoanCard = ({
  id,
  icon,
  title,
  description,
  content = {},
  className,
  loanData,
} = {}) => {
  const { setSelectedLoan } = useLoans()

  const iconSrc = `/images/icons/${icon}.svg`

  const handleLoanSelection = () => {
    makeTrackings.formContinued({
      formId: loansFormId,
      formName: 'loans',
      stepId: 'e1f42812-a734-40b0-81ae-b32c32a04525',
      stepName: 'loans-subproducts',
      stepNumber: 3,
    })

    setSelectedLoan(loanData)
    navigate('/emprestimos/resumo')
  }

  return (
    <BaseCard className={classNames('loan-card', className)} id={id}>
      <div>
        <img src={iconSrc} aria-hidden="true" className="loan-card__icon" />

        <h3 className="loan-card__title">{title}</h3>
        <p className="loan-card__description">{description}</p>

        <div className="loan-card__content">
          <p className="loan-card__content-title">{content.title}</p>
          <If
            condition={content.titleDetail}
            renderIf={
              <p className="loan-card__content-detail">{content.titleDetail}</p>
            }
          />
          <hr className="loan-card__divider" />
          <If
            condition={content.description}
            renderIf={
              <p className="loan-card__content-description">
                {content.description}
              </p>
            }
          />
          <If
            condition={content.descriptionDetail}
            renderIf={
              <p className="loan-card__content-detail">
                {content.descriptionDetail}
              </p>
            }
          />
        </div>
      </div>

      <div>
        <Button className="loan-card__button" onClick={handleLoanSelection}>
          Continuar
        </Button>
        <p className="loan-card__disclaimer">
          Proposta sujeita a análise de crédito
        </p>
      </div>
    </BaseCard>
  )
}

export default LoanCard
