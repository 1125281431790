import * as classNames from 'classnames'
import EditPen from '../../../static/images/icons/brush.svg'

import './EditButton.scss'

const EditButton = ({ onEdit, className }) => {
  if (!onEdit) return null

  return (
    <button className={classNames('edit-button', className)} onClick={onEdit}>
      <EditPen />
      <div className="vertical-divider" />
      <span>Editar</span>
    </button>
  )
}

export default EditButton
