import * as classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import ArrowBack from '../../../static/images/icons/arrow-back.svg'
import ArrowForward from '../../../static/images/icons/arrow-forward.svg'

import './CardCarousel.scss'

const CardCarousel = ({ cards = [] } = {}) => {
  const [activeCard, setActiveCard] = useState(0)

  const slidesRef = useRef(null)

  useEffect(() => {
    slidesRef.current.isByButton = false
    slidesRef.current.onscroll = handleSlideScroll
  }, [])

  const handleSlideScroll = (e) => {
    if (!slidesRef?.current) return

    const scrollLeft = Math.round(e.target.scrollLeft)
    const scrollWidth = Math.round(e.target.scrollWidth)

    // //* INFO: 312 = 296 (card fixed width) + 16 (card right margin)
    const cardWidthPlusMargin = 296 + 16

    if (!e.target.isByButton) {
      if (scrollLeft >= scrollWidth - window.innerWidth) {
        setActiveCard(cards.length - 1)
      } else if (scrollLeft >= cardWidthPlusMargin) {
        setActiveCard(1)
      } else {
        setActiveCard(0)
      }
    }
  }

  const handleBackCard = () => {
    if (activeCard === 0) return

    const newState = activeCard - 1

    setActiveCard((state) => state - 1)

    slidesRef.current.isByButton = true

    slidesRef.current.scroll({
      behavior: 'smooth',
      left: newState * 296,
    })

    setTimeout(() => {
      slidesRef.current.isByButton = false
    }, 500)
  }

  const handleForwardCard = () => {
    if (activeCard === 2) return

    const newState = activeCard + 1

    setActiveCard((state) => state + 1)

    slidesRef.current.isByButton = true

    slidesRef.current.scroll({
      behavior: 'smooth',
      left: newState * 296,
    })

    setTimeout(() => {
      slidesRef.current.isByButton = false
    }, 500)
  }

  return (
    <div className="card-carousel">
      <div className="card-carousel__content" ref={slidesRef}>
        {cards.map((card) =>
          React.cloneElement(card, {
            className: classNames(cards.className, 'carousel-content-card'),
          })
        )}
      </div>

      <div className="card-carousel__controls">
        <div className="card-carousel__buttons">
          <button
            className="card-carousel__button"
            disabled={activeCard === 0}
            onClick={handleBackCard}
          >
            <ArrowBack />
          </button>
          <button
            className="card-carousel__button"
            disabled={activeCard === cards.length - 1}
            onClick={handleForwardCard}
          >
            <ArrowForward />
          </button>
        </div>

        <div
          className={classNames(
            'card-carousel__stepper',
            `card-carousel__stepper--${parseInt(
              ((activeCard + 1) / cards.length) * 100
            )}`
          )}
        />
      </div>
    </div>
  )
}

export default CardCarousel
